import { forwardRef } from 'react';
import { S3Img, S3ImgProps } from '../s3-img';
import { getUserPath } from './get-path';
import { MediaOpts } from '../get-s3-url';

export const S3UserImg = forwardRef<
  HTMLImageElement,
  {
    userId: string;
    s3Key?: string;
  } & MediaOpts &
    Omit<S3ImgProps, 'path'>
>(function S3UserImg({ userId, s3Key, ...rest }, ref) {
  return (
    <S3Img
      ref={ref}
      path={s3Key ? getUserPath(userId, s3Key) : undefined}
      {...rest}
    />
  );
});
